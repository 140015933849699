import { Component, OnInit } from '@angular/core';

interface Product {
  name: string;
  website: string;
  image: string; 
  year: string;
  description: string;
  youtubeUrls: string[]; 
}

@Component({
  selector: 'app-corporate-products',
  templateUrl: './corporate-products.component.html',
  styleUrls: ['./corporate-products.component.scss']
})
export class CorporateProductsComponent implements OnInit {

  readonly title = 'Corporate Security Products'
  readonly intro = 'An overview of corporate security products I have worked on for Fortinet, encompassing 5 years of enterprise development experience.'

  readonly products: Product[] = [
    {
      name: "FortiPam",
      website: "https://www.fortinet.com/products/fortipam",
      image: "assets/images/FortiPamImage.png",
      year: "2021-2022",
      youtubeUrls: [
        'https://www.youtube.com/embed/9HmuCskJKY8', // Converted URL
        'https://www.youtube.com/embed/jnEILYNzFfY'  // Converted URL
      ],
      description: `FortiPAM offers privileged access management across all IT systems, monitoring critical accounts and processes. I was the original developer on the product, spearheading the entire frontend development effort from conception. Allows admins to configure computers on the network to be associated with staff accounts, and staff members can click to securely launch remote sessions with network computers at the click of a button. It's like LastPass, but for remote control of computers!`
    },
    {
      name: "FortiAuthenticator",
      website: "https://www.fortinet.com/products/identity-access-management/fortiauthenticator",
      image: "assets/images/FACimage.jpg",
      year: "2018-2021",
      youtubeUrls: ['https://www.youtube.com/embed/JFoPXaT3ME0', 'https://www.youtube.com/embed/cwHlTcnVTZc'], // Converted URL
      description: `FortiAuthenticator provides identity and access management (IAM) services to prevent breaches resulting from unauthorized users gaining access to a network or inappropriate levels of access granted to valid users. FortiAuthenticator ensures only the right person can access your sensitive resources and data at the right time.`
    },
    // ... other products
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
