import { Component, OnInit } from '@angular/core';



interface Project {
  name: string;
  website: string;
  image : string;
  phoneGif?: string;
  youtubeUrls: string[]; // Updated to include YouTube URLs
  year: string;
  description: string;
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  readonly title = 'Founded Apps'
  readonly intro = 'A list of live apps currently founded and hosted by Bryn Beaudry'

  readonly projects: Project[] = [
    {
      "name": "Tendeq",
      "website": "https://tendeq.com",
      "image": "assets/images/tendeq-square.gif",
      "phoneGif": '',
      "youtubeUrls": [], // Add any YouTube URL(s) if available
      "year": "2024",
      "description": `Tendeq is a website that demonstrates the capabilities of modern web development technologies: Built in just an hour using Next.js, Shadcn, V0, and TailwindCSS, this website is a testament to what can be achieved with efficient coding and streamlined frameworks.
    
      Currently under development, Tendeq aims to assist private equity lenders in managing their investments more effectively. One day it will offer real-time insights into portfolio performance and supports automated workflows for reporting, document management, and task tracking. The platform will also include tools for seamless collaboration with brokers and lenders.
`
    },    
    {
      name: "Schedulific",
      website: "https://schedulific.com",
      image: "assets/images/schedulific.png",
      phoneGif: "assets/images/schedulificphone.gif",
      youtubeUrls: ["https://www.youtube.com/embed/qJP6X8pU97I"], // YouTube URL for Schedulific
      year: "2023",
      description: `Schedulific is a SaaS solution  tailored for multipurpose event spaces. This  platform is crafted to improve the way businesses handle their booking processes, customer payments, and queries, enhancing efficiency and client satisfaction.

      Schedulific is the simplifies transactions for property space bookings, ensuring smooth bookings from a link on your website, adding addons, sending emails to clients, and providing a booking area where customers can ask questions. The platform integrates your Google Calendar, allowing for seamless scheduling and effective time management.

      Another key aspect of Schedulific is its AI chatbot. This chatbot is designed to handle routine customer queries, escalating only complex issues to human operators. This not only saves time but also enhances the customer service experience.

      Additionally, Schedulific excels in managing multiple booking types. Whether it's a corporate event, a wedding, or a casual gathering, the platform can cater to a wide range of customer needs and booking preferences. The ability to manage multiple properties through one interface is a boon for business owners with diverse property portfolios.`
    },
    {
      name: "Larker",
      website: "https://larker.world",
      image: "assets/images/larker.png",
      phoneGif: "assets/images/larkerphone.gif",
      youtubeUrls: ["https://www.youtube.com/embed/cUUbD6Q9YgA"], // YouTube URL for Larker
      year: "2023",
      description: `Larker introduces a gamified approach to exploration. Users can earn points and level up by discovering new locations and interacting with them uniquely. The accumulated points have the potential to unlock newer features in the future, such as enabling users to leave their mark on the map, interact differently with other players, and even earn rewards.

Drawing inspiration from popular dating apps, Larker offers a Tinder-esque experience for art walks, allowing users to swipe through and engage with cultural artifacts, viewpoints, and public art around them.

Currently, the application allows users and their friends to appear on the map, gain points, and level up by creating or interacting with map markers. For a user to earn points by interacting with these markers, they must be within a proximity of about 100 meters, roughly the length of a football field.

Larker's focus is not just to be another map or travel app. It encourages users to get out and engage more deeply with their surroundings, all while having a fun and interactive experience. It's an application that seamlessly merges AI, gamification, and cultural discovery, making city exploration a more enriching experience.`
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
