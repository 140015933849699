import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appLazyBackground]'
})
export class LazyBackgroundDirective {

  @Input() appLazyBackground: string;

  constructor(private el: ElementRef) {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadBackgroundImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);

    // Initially set the element to be transparent
    this.el.nativeElement.classList.add('fade-in-background');
  }

  loadBackgroundImage() {
    // Set the background image
    const img = new Image();
    img.src = this.appLazyBackground;
    img.onload = () => {
      this.el.nativeElement.style.backgroundImage = `url('${this.appLazyBackground}')`;
      // Once the image is loaded, change opacity to 1 to fade in
      this.el.nativeElement.style.opacity = '1';
    };
  }
}
