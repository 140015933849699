import { Component, OnInit } from '@angular/core';

interface Customer {
  name: string;
  website: string;
  image: string;
  year: string;
  description: string;
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  readonly title = 'Happy Customers'
  readonly intro = 'We work hard and build good relationships with main customers.'

  readonly customers: Customer[] = [
    {
      name: "Lux Bio",
      website: "https://www.lux-bio.com",
      image: "assets/images/luxbio.jpg",
      year: "2023",
      description: "Lux Bio (formerly Nyoka Design Labs) is a biotech startup for whom I provided security consulting, and we are in in talks to work with biologists to implement ML pipelines to expediate their protein sequencing to produce proteins that emit light for longer periods of time, and increasing the brightness at the same time. I investigated the origins of phishing attacks targeted at their new hires. Additionally, I aided them in establishing an employee training program and furnished documentation for secure onboarding processes. I also switched their authentication over to SIM jacking resistant 2FA for their most vulnerable accounts, and instructed them on how to make this a part of their onboarding processes."
    },
    {
      name: "SimpleStudy",
      website: "https://simplestudy.io",
      image: "assets/images/simplestudy.jpg",
      year: "2024",
      description: "SimpleStudy is a startup that aims to revolutionize the way students study. When a friend's European online education platform faced uncertainty after their CTO left, I intervened. Initially focusing on securing intellectual property, my role expanded to streamlining infrastructure and mentoring a new engineering team. We consolidated databases to enhance scalability and translated complex technical challenges into clear strategies. My involvement left the project poised for success, ready for future growth and international expansion, supporting over 60,000 users.",
    },
    {
      name: "REPOWR",
      website: "https://repowr.com",
      image: "assets/images/repowr.jpg",
      year: '2023',
      description: "REPOWR is a trucking logistics software startup based in Chattanooga, Tennessee. My contributions encompassed the development of enhanced APIs and processes, particularly in the domains of social analytics and customer insurance verification."
    },
    {
      name: "The Agora MarketPlace",
      website: "https://agora.art",
      image: "assets/images/agora.jpg",
      year: "2019-2023",
      description: "The Agora Marketplace was conceptualized by Vancouver developer Taylor Aucoin. Taylor approached me to build the initial prototype, entrusting me with designing the database and the backend infrastructure. Although I transitioned to a consulting role, I supervised several BCIT industry-sponsored student projects, assisting in training students in Object Relational and Business Domain Modeling. Throughout Agora's trajectory, I've consistently provided solutions for intricate backend and frontend algorithms, ensuring seamless operations."
    },
    {
      name: "Okanagan Valley School of Massage Therapy",
      website: "https://www.ovcmt.com",
      image: "assets/images/ovcmt.jpg",
      year: "2017-2020",
      description: "For the Okanagan Valley School of Massage Therapy, I innovatively developed features for a touch-screen-facilitated system that enabled the creation of highly customizable college schedules. I ensured the schedules were user-friendly, aesthetically pleasing for print, and easy to comprehend. I also undertook full-stack modifications, adding new features and rectifying elusive bugs."
    },
    {
      name: "Floating World",
      website: "https://www.floatingworld.ca",
      image: "assets/images/floating.jpg",
      year: "2022",
      description: "Collaborating with Aaron Handford at Floating World, I was pivotal in resolving a critical issue for Mike's Money Talks - a renowned financial forecast publication. Due to massive demand surges after talks, their server struggled to manage the load from users making course purchases. My intervention identified the bottlenecks, optimized server autoscaling configurations, and provided guidelines for future autoscaling adjustments."
    },
    {
      name: "Mike’s Money Talks",
      website: "https://mikesmoneytalks.ca",
      image: "assets/images/mikes.jpg",
      year: "2022",
      description: "Mike's Money Talks faced server overloads during peak course demand after their talks. In collaboration with Floating World, I identified and rectified server bottlenecks, ensuring optimal autoscaling for consistent user experience."
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
