import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  readonly faLinkedin = faLinkedin

  currentSection = 'home';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  setCurrentSection(section: string) {
    this.currentSection = section
  }



  /**
 * Window scroll method
 */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }



  /**
     * Toggle navbar
     */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

}
