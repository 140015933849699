<!-- Blog start -->
<section class="section" id="blog">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mb-5">
            <h3 class="title mb-3">{{title}}</h3>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-4" *ngFor="let c of customers">
          <div class="blog-box mb-4 mb-lg-0">
            <a href="{{c.website}}">
              <img src="{{c.image}}" alt="" class="img-fluid d-block mx-auto customers rounded rounded-image">
            </a>
            
            <ul class="list-inline text-muted text-uppercase text-center font-size-15 font-weight-medium mt-3 mb-2">
              <li class="list-inline-item me-3">
                <i-feather class="icon-size-15 icon me-1" name="calendar"></i-feather>
                {{c.year}}
              </li>
              <li class="list-inline-item">
                <i-feather class="icon-size-15 icon me-1" name="user"></i-feather>Bryn
              </li>
            </ul>
            <a href="{{c.website}}" class="text-dark  fw-bold h5">{{c.name}}</a>
            <p class="text-muted font-size-15">{{c.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog End -->
  