
      <app-hero></app-hero>
      <app-corporate-products></app-corporate-products>
      <app-projects></app-projects>
      <app-customers></app-customers> 
      <app-story></app-story>
      <!-- <app-features></app-features>
      <app-pricing></app-pricing> -->
      <app-services></app-services>
      <app-contact></app-contact>
      <app-footer></app-footer>
