<!-- Blog start -->
<section class="section" id="blog">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">{{title}}</h3>
          <p class="text-muted font-size-15">{{intro}}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngFor="let c of projects">
      <div class="col-lg-4">
        <div class="blog-box mb-4 mb-lg-0">
          <div class="img-div">
            <a href="{{c.website}}">
              <img src="{{c.image}}" alt="" class="img-fluid d-block mx-auto projects rounded">
            </a>
          </div>
          <div>
            <ul class="list-inline text-muted text-uppercase text-center font-size-15 font-weight-medium mt-3 mb-2">
              <li class="list-inline-item me-3">
                <i-feather class="icon-size-15 icon me-1" name="calendar"></i-feather>
                {{c.year}}
              </li>
              <li class="list-inline-item">
                <i-feather class="icon-size-15 icon me-1" name="user"></i-feather>Bryn
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="blog-box mb-4 mb-lg-0">
          <a href="{{c.website}}" class="text-dark  fw-bold h5">{{c.name}}</a>
          <p class="text-muted font-size-15">{{c.description}}</p>
          <!-- Display the phone gif -->
          <div class="phone-gif-container">
            <img src="{{c.phoneGif}}" loading="lazy" class="phone-gif">
          </div>
          <!-- YouTube previews container -->
          <h6 *ngIf="c.youtubeUrls.length" class="title">Video Demos:</h6>
          <div class="youtube-previews-container">
              <div *ngFor="let url of c.youtubeUrls" class="youtube-preview">
                  <iframe [src]="url | safeUrl" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
              </div>
          </div>
          <!-- YouTube previews end here -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->
