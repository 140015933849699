<!-- Pricing Start -->
<section class="section bg-light" id="pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Choose Your Plan</h3>
          <p class="text-muted font-size-15">Et harum quidem rerum facilis est et expedita distinctio nam libero
            tempore cum soluta nobis eligendi cumque.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="pricing-box rounded text-center p-4">
          <div class="pricing-icon-bg my-4">
            <i class="mdi mdi-account h1"></i>
          </div>
          <h4 class="title mb-3">Simple</h4>
          <h1 class="fw-bold mb-0"><b><sup class="h4 me-2 fw-bold">$</sup>19</b></h1>
          <p class="text-muted font-weight-semibold">User / Month</p>
          <ul class="list-unstyled pricing-item mb-4">
            <li class="text-muted">Bandwidth: 1GB</li>
            <li class="text-muted">Onlinespace: 500MB</li>
            <li class="text-muted">Support: Yes</li>
          </ul>
          <a href="javascript: void(0);" class="btn btn-outline-primary pr-btn">Buy Now</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="pricing-box rounded text-center active p-4">
          <div class="pricing-icon-bg my-4">
            <i class="mdi mdi-account-multiple h1"></i>
          </div>
          <h4 class="title mb-3">Basic</h4>
          <h1 class="fw-bold mb-0"><b><sup class="h4 me-2 fw-bold">$</sup>49</b></h1>
          <p class="text-muted font-weight-semibold">User / Month</p>
          <ul class="list-unstyled pricing-item mb-4">
            <li class="text-muted">Bandwidth: 2GB</li>
            <li class="text-muted">Onlinespace: 1GB</li>
            <li class="text-muted">Support: Yes</li>
          </ul>
          <a href="javascript: void(0);" class="btn btn-primary pr-btn">Buy Now</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="pricing-box rounded text-center p-4">
          <div class="pricing-icon-bg my-4">
            <i class="mdi mdi-account-group h1"></i>
          </div>
          <h4 class="title mb-3">Pro</h4>
          <h1 class="fw-bold mb-0"><b><sup class="h4 me-2 fw-bold">$</sup>89</b></h1>
          <p class="text-muted font-weight-semibold">User / Month</p>
          <ul class="list-unstyled pricing-item mb-4">
            <li class="text-muted">Bandwidth: 3GB</li>
            <li class="text-muted">Onlinespace: 1.5GB</li>
            <li class="text-muted">Support: Yes</li>
          </ul>
          <a href="javascript: void(0);" class="btn btn-outline-primary pr-btn">Buy Now</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Pricing End -->
