<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)" id="story">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <p class="font-weight-medium text-uppercase mb-2"><i
                        class="mdi mdi-chart-bubble h2 text-primary me-1 align-middle"></i> Our story</p>
                <h3 class="font-weight-semibold line-height-1_4 mb-4"><b>Bryn</b> registers a <b>consultancy:</b>
                </h3>
                <p class="text-muted font-size-15 mb-4">Welcome to my portfolio, showcasing my journey and expertise in
                    software development. At BCIT, my commitment went beyond academics; I discovered a deep love for
                    creative technology and treated my time there as an intensive training ground in mobile and
                    full-stack development. This drive didn't stop at the classroom door. I actively sought out projects
                    and clients, using every opportunity to refine my skills and offset my student debts.</p>
                <p class="text-muted font-size-15 mb-4">The demand for my skills and the consistent influx of project
                    requests inspired me to establish Beaudry Consulting in 2020. This step was a natural progression,
                    allowing me to formalize my passion for software development and consulting. Operating from my
                    pandemic-induced home office, I ensured that my consultancy would be a hub for creative and
                    effective software solutions, catering to a diverse range of needs.</p>
                <p class="text-muted font-size-15 mb-4">This website is a testament to my dedication and love for
                    software development. Even while working full-time, I have always made room for consulting, driven
                    by a genuine passion for my craft. In my free time, you'll find me engrossed in various projects,
                    constantly sharpening my skills in programming and web technologies. If you're looking for
                    assistance or expert guidance in software development, please feel free to reach out. I'm here to
                    help, eager to share my experiences, and ready to guide you in the right direction. 😊</p>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <div class="mt-4 mt-lg-0">
                    <img src="assets/images/features-img-1.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
    </div>
</section>