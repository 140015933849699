<!-- Privacy Policy Start -->
<section class="section bg-light feather-bg-img" style="background-image: url(assets/images/features-bg-img.png);"
  id="privacy-policy">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Privacy Policy</h3>
          <p class="text-muted font-size-15">We respect your privacy and are committed to protecting it. The following policy outlines our practices regarding the collection and use of your data.</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/hero-8.png" alt="" class="img-fluid d-block mx-auto">
        </div>
      </div>

      <div class="col-lg-5 offset-lg-1">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-shield-check h2 text-primary me-1 align-middle"></i> Data Collection</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Our website does not collect any personal data from its visitors.</h3>
        <p class="text-muted font-size-15 mb-4">We understand the importance of safeguarding your privacy and have designed our website to function without collecting any personally identifiable information from our users. We do not use cookies, tracking scripts, or third-party tools to collect data about our visitors.</p>
        <p class="text-muted mb-2">
          <i-feather class="icon-xs me-1" name="user-x"></i-feather>No personal information collected
        </p>
        <p class="text-muted">
          <i-feather class="icon-xs me-1" name="cookie"></i-feather>No cookies or tracking scripts used
        </p>
        <div class="mt-5">
          <a href="javascript: void(0);" class="btn btn-primary me-2">Learn More</a>
          <a href="javascript: void(0);" class="btn btn-soft-primary">Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Privacy Policy End -->