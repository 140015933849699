<!-- Hero Start -->
<!-- <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home"> -->
<section id="home" class="hero-1-bg" appLazyBackground="/assets/images/whitegoldwave.svg">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <h1 class="hero-1-title text-dark text-white-outer-glow fw-bold text-shadow mb-4">Change the Web,</h1>
          <h2 class="hero-1-title text-dark text-white-outer-glow fw-bold text-shadow mb-4">&nbsp;&nbsp;Change the world.</h2>
          <div id="small-main-image" class="col-lg-6 col-md-10">
            <div class=" mt-5 mt-lg-0">
              <img src="assets/images/webmobileavatar.svg" loading="lazy" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
          <div class="w-75 mb-5 mb-lg-0 hide-mobile">
            <p class="mb-0 pb-5 text-light text-black-outer-glow font-size-17">Software development for custom full stack web and mobile applications.</p>
          </div>
        </div>
        <div id="large-main-image" class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/webmobileavatar.svg" loading="lazy" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Hero End -->